import { graphql } from "gatsby"
import SEO, { getSeoProps } from "../../components/Common/SEO"
import ForProducersSection from "../../components/Sections/ForProducersSection"
import getBannerProps from "../../components/Hero/getHeroBannerProps"
import HeroProducerHeadline from "../../components/Hero/HeroProducerHeadline"
// import getMainPath from "../components/Common/MainPaths"
// import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
// import { useIsAuthenticated } from "@azure/msal-react"

/**
 * @description Bygges opp likt som de andre sidene, oversikt, ingress, visning av kort
 * kort sorteres etter nyeste først (created date)
 * @param param0
 */
const forProdusenter = ({ location, data }) => {
  // const mainPath = getMainPath("producers")

  const {
    allContentfulGeneric: { nodes: allContent },
    contentfulGeneric: content,
  } = data

  allContent.sort((a, b) => a.order - b.order)

  // Parse the content and create the props needed by SEO component.
  const helmetProps = getSeoProps(content, location, "article")
  // Parse the content, and get the needed banner props
  const heroBannerProps = getBannerProps(content)

  return (
    <>
      <SEO {...helmetProps} />

      <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <header className="lg:px-48">
          <HeroProducerHeadline {...heroBannerProps} />
        </header>
        <div className="">
          {allContent.map((topic, index) => {
            return <ForProducersSection key={topic.id} data={topic} imgPosition={index % 2 === 0 ? "left" : "right"} />
          })}
        </div>
      </div>
    </>
  )
}

export const topicQuery = graphql`
  {
    allContentfulGeneric(
      filter: {
        node_locale: { eq: "nb-NO" }
        slug: {
          in: ["produksjonsplanlegging", "produksjonsplanlegging-regelverk", "vedtekter", "bli-eier", "briskebywebshop"]
        }
      }
    ) {
      nodes {
        id
        contentful_id
        slug
        node_locale
        title
        createdAt(formatString: "YYYY-MM-D")
        updatedAt(formatString: "YYYY-MM-D")
        metadataDescription
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
            }
          }
          url
        }
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
      }
    }
    contentfulGeneric(slug: { eq: "for-produsenter" }, node_locale: { eq: "nb-NO" }) {
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
      updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
      body {
        childMdx {
          timeToRead
          body
        }
      }
      author {
        name
        firstName
        lastName
        twitter
        twitterId
      }
      heroImage {
        id
        public_id
        context {
          custom {
            Credits
            alt
          }
        }
        url
        secure_url
        width
        height
        format
      }
      node_locale
      title
      metadataDescription
      slug
    }
  }
`

export default forProdusenter
