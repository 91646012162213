import { Ingress } from "../Styling/Body"
import { HeroTitle } from "../Styling/Titles"

const HeroHeadline = (props) => {
  return (
    <>
      <header>
        <HeroTitle>
          <span className="text-center">{props.title}</span>
        </HeroTitle>
        <div className="flex justify-center mt-16">
          <div className="px-2 max-w-sm md:max-w-2xl">
            <Ingress>{props.ingress}</Ingress>
          </div>
        </div>
      </header>
    </>
  )
}

export default HeroHeadline
